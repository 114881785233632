// src/main.js
import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
import store from './store';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './index.css';
import i18n from './i18n'; // Import i18n

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n, // Gunakan i18n
  render: h => h(App),
  mounted() {
    AOS.init();
  },
}).$mount('#app');
