<!-- eslint-disable vue/multi-word-component-names -->
<template>
    
    <div>
        <div class="translate">
        <LanguageSwitcher  />
        </div>
    <div class="ArtikelDestop">
        <div class="containerrr">
            <div>
               
                <div class="titleartikell">
                    <p>{{ $t('message.title') }}</p>
                </div>
                <div class="imgdivv">
                    <img class="imgartikel1" :src="require('@/assets/img/img1.jpg')" alt="">
                </div>
                <div class="artikel">
                    <p style="margin-bottom: 20px; margin-top: 20px">
                        {{ $t('message.text1') }}
                    </p>
                    <p style="margin-bottom: 20px; ">
                        {{ $t('message.text2') }}
                    </p>
                    <p style="margin-bottom: 20px; ">
                        {{ $t('message.text3') }}
                    </p>
                    <p class="titleartikel2">
                        {{ $t('message.text4') }}
                    </p>
                    <p style="margin-bottom: 20px; ">
                        {{ $t('message.text5') }}
                    </p>
                    <p class=" space-y-1 list-decimal list-inside">
                    <ul class="space-y-4 text-gray-900 list-disc list-inside dark:text-gray-900">
                        <ol class="ps-5 mt-2 space-y-1 list-decimal list-inside">
                            <li>{{ $t('message.text6') }} </li>
                            <li>{{ $t('message.text7') }}</li>
                            <li>{{ $t('message.text8') }}</li>
                            <li>{{ $t('message.text9') }}</li>
                        </ol>
                    </ul>
                    </p>
                    <p class="titleartikel3">
                        {{ $t('message.text10') }}
                    </p>
                    <p style="text-align: left; margin-bottom: 20px;">{{ $t('message.text11') }}</p>
                    <p class="" style="font-size: 22px;font-weight: bold;color: #3687aa;margin-top: 20px;">
                        {{ $t('message.text12') }}
                    </p>
                    <p>
                        {{ $t('message.text13') }}
                    </p>
                    <p>
                    <ul class="space-y-4 text-gray-900 list-disc list-inside dark:text-gray-900">
                        <ol class="ps-5 mt-2 space-y-1 list-decimal list-inside">
                            <li> {{ $t('message.text15') }}</li>
                            <li> {{ $t('message.text16') }}</li>
                            <li> {{ $t('message.text17') }}</li>
                            <li> {{ $t('message.text18') }}</li>
                            <li> {{ $t('message.text19') }}</li>
                            <li> {{ $t('message.text20') }}</li>
                            <li> {{ $t('message.text21') }}</li>
                            <li> {{ $t('message.text22') }}</li>
                            <li>{{ $t('message.text23') }}</li>
                        </ol>
                    </ul>
                    </p>
                    <p class="titleartikel4">
                        {{ $t('message.text25') }}
                    </p>
                    <p>
                        {{ $t('message.text26') }}
                    </p>
                    <p>
                    <ul class="space-y-4 text-gray-900 list-disc list-inside dark:text-gray-900">
                        <ol class="ps-5 mt-2 space-y-1 list-decimal list-inside">
                            <li>{{ $t('message.text27') }}</li>
                            <li>{{ $t('message.text28') }}</li>
                        </ol>
                    </ul>
                    </p>
                    <p>
                        {{ $t('message.text29') }}
                    </p>
                    <p>
                    <ul class="space-y-4 text-gray-900 list-disc list-inside dark:text-gray-900">
                        <ol class="ps-5 mt-2 space-y-1 list-decimal list-inside">
                            <li>{{ $t('message.text30') }}</li>
                            <li>{{ $t('message.text31') }}</li>
                            <li>{{ $t('message.text32') }}</li>
                        </ol>
                    </ul>
                    </p>
                    <p>
                        {{ $t('message.text33') }}
                    </p>
                    <p>
                    <ul class="space-y-4 text-gray-900 list-disc list-inside dark:text-gray-900">
                        <ol class="ps-5 mt-2 space-y-1 list-decimal list-inside">
                            <li>{{ $t('message.text34') }}</li>
                            <li>{{ $t('message.text35') }}</li>
                            <li>{{ $t('message.text36') }}</li>
                            <li>{{ $t('message.text37') }}</li>
                            <li>{{ $t('message.text38') }}</li>
                            <li>{{ $t('message.text39') }}</li>
                        </ol>
                    </ul>
                    </p>
                    <p style="text-align: left; margin-bottom: 20px;">
                        {{ $t('message.text40') }}
                    </p>
                    <p>
                        {{ $t('message.text41') }}
                    </p>
                    <p class="titleartikel5">
                        {{ $t('message.text42') }}
                    </p>
                    <p style="text-align: left; margin-bottom: 20px;">
                        {{ $t('message.text43') }}
                    </p>
                    <p style="text-align: left; margin-bottom: 20px;">
                        {{ $t('message.text44') }}
                    </p>
                    <p style="text-align: left; margin-bottom: 20px;">
                        {{ $t('message.text45') }}
                     <br>
                     {{ $t('message.text46') }}
                      <br>
                      {{ $t('message.text47') }}
                     <br>
                    </p>
                    <img style="text-align: left; margin-bottom: 20px; width: 100%; height: 500px; object-fit: cover;border-radius: 10px;"
                        :src="require('@/assets/img/SLF-pabrik.jpg')" alt="">
                    <p>
                        {{ $t('message.text48') }}
                    </p>
                    <p class="titleartikel6">
                        {{ $t('message.text49') }}
                    </p>
                    <p style="text-align: left; margin-bottom: 20px;">
                        {{ $t('message.text50') }}
                    </p>
                    <p style="text-align: left; margin-bottom: 20px;">
                        {{ $t('message.text51') }}
                    </p>
                    <p style="text-align: left; margin-bottom: 20px;">
                        {{ $t('message.text52') }}
                    </p>
                    <p style="text-align: left; margin-bottom: 20px;">
                        {{ $t('message.text53') }}
                    </p>
                    <p style="text-align: left; margin-bottom: 20px;">
                    <ul class="space-y-4 text-gray-900 list-disc list-inside dark:text-gray-900">
                        <ol class="ps-5 mt-2 space-y-1 list-decimal list-inside">
                            <li>{{ $t('message.text54') }} </li>
                            <li>{{ $t('message.text55') }}</li>
                            <li>{{ $t('message.text56') }}</li>
                        </ol>
                    </ul>
                    </p>
                    <p style="text-align: left; margin-bottom: 20px;">
                        {{ $t('message.text57') }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <CustomFooter style="font-size: 12px;"></CustomFooter>
    </div>
</template>

<script>
import CustomFooter from '@/components/CustomFooter.vue';
import LanguageSwitcher from '../components/LanguageSwitcher.vue';
export default {
    name: 'AxrtikelOne',
    components: {
        CustomFooter,
        LanguageSwitcher
    },
    views: {
    }
}
</script>

<style>
.containerrr {
    margin-left: 1%;
    margin-right: 1%;
    display: flex;
    justify-content: center;
    /* background-color: aqua; */
}

.imgdivv {
    width: 100%;
    height: 400px;
    /* Tinggi tetap 500px */
    overflow: hidden;
    /* Untuk memastikan gambar tidak keluar dari div */
    border-radius: 10px;
}

.imgartikel1 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Menjaga rasio aspek gambar dan memastikan gambar tidak pecah */
    border-radius: 10px;

}


.artikel p {
    text-align: left;
}

.titleartikell p {
    font-weight: bold;
    font-size: 40px;
    padding: 20px;
}

.angkerBackHom i {
    font-size: 100px;
}

.angkerBackHome {
    position: absolute;
    left: 10px;
    top: 6px;
    background-color: rgba(white, white, white, 0.8);
    border-radius: 50%;
    padding: 5px;
}

.angkerBackHome::hover {
    background-color: rgba(white, white, white, 0.8);
}

.titleartikel2,
.titleartikel3,
.titleartikel4,
.titleartikel5,
.titleartikel6 {
    font-size: 22px;
    font-weight: bold;
    color: #3687aa;
    margin-top: 20px;
    margin-bottom: 20px;
}
.translate{
    position: sticky;
    top: 0; /* Posisi dari atas viewport */
    display: flex;
    gap: 10px;
    margin: 10px 0;
    background-color: white; /* Untuk memastikan tombol tidak kehilangan latar belakang saat digulir */
    z-index: 1000; /* Pastikan tombol tetap di atas konten lainnya */
    padding-left: 50px;
    border-bottom: 1px solid red;
}
@media (max-width: 600px) {
    .ArtikelDestop{
        padding-left: 10px;
        padding-right: 10px;
    }
    .titleartikell p {
    font-weight: bold;
    font-size: 20px;
    padding-top: 40px;
}
.angkerBackHome {
    position: absolute;
    left: 10px;
    top: 6px;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
}
.titleartikel2,
.titleartikel3,
.titleartikel4,
.titleartikel5,
.titleartikel6 {
    font-size: 17px;
    font-weight: bold;
    color: #3687aa;
    margin-top: 20px;
    margin-bottom: 20px;
}
.translate{
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;

}
}
</style>