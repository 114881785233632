<template>
    <div class="language-switcher">
        <!-- <div>
        <a href="/" class="angkerBackHome" style="color: black;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"
                        class="bi bi-arrow-bar-left" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                            d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5" />
                    </svg>
                </a>
            </div> -->
      <button
        :class="{ active: currentLanguage === 'en' }"
        @click="switchLanguage('en')"
      >
        English
      </button>
      <button
        :class="{ active: currentLanguage === 'id' }"
        @click="switchLanguage('id')"
      >
        Indonesia
      </button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LanguageSwitcher',
    data() {
      return {
        currentLanguage: this.$i18n.locale // Menyimpan bahasa yang aktif saat ini
      };
    },
    methods: {
      switchLanguage(lang) {
        this.$i18n.locale = lang;
        this.currentLanguage = lang; // Update bahasa aktif
      }
    }
  }
  </script>
  
  <style scoped>
  .language-switcher {
    display: flex;
    gap: 10px;
    margin: 10px 0;
  }
  button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: black;
    
  }
  button.active {
    background-color: #007bff;
    color: white;
  }
  button:hover {
    background-color: #f0f0f0;
  }
  </style>
  