// src/i18n.js
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  en: {
    message: {
      title: 'The Importance of SLF for Factory Buildings',
      text1: 'Currently, the Indonesian government requires buildings, especially factories, to take care of SLF or certificate of fitness for function. SLF is a certificate issued by the local government for buildings that have been completed according to the IMB and have met the technical feasibility requirements according to the function of the building based on the results of the inspection from the building expert team or SLF consultant services.',
      text2: ' The main purpose of issuing a certificate of functional fitness is as a requirement for the use of buildings. In addition, the existence of a functional fitness certificate can be interpreted as the building you are using has been tested for reliability.',
      text3: ' However, before we discuss the obligation to manage a certificate of fitness for factory buildings, there are important points that you must pay attention to. For that, see this article to the end!',
      text4: ' Regulations on SLF management obligations',
      text5: ' As previously explained, the management of functional fitness certificates has been regulated by the Indonesian government through clear laws and regulations. The laws and regulations regarding the obligation to manage a certificate of fitness for function are as follows:',
      text6: 'Law of the Republic of Indonesia Number 28 of 2002 concerning Buildings.',
      text7: 'Government Regulation (PP) Number 36 of 2005 concerning Regulations for the Implementation of Law Number 28 of 2002 concerning Buildings.',
      text8: 'Regulation of the Minister of Public Works and Public Housing Number 27/PRT/M/2018 concerning Building Functional Fitness Certificates.',
      text9: ' Regulation of the Minister of Public Works and Public Housing of the Republic of Indonesia Number 3 of 2020 concerning Amendments to the Regulation of the Minister of Public Works and Public Housing Number 27/PRT/M/2018 concerning Building Functional Suitability Certificates.',
      text10: 'What will be assessed in the management of factory SLF?',
      text11: 'In order for the local government to issue an SLF for your factory, there are two main requirements that must be met. First, are administrative requirements. Second, is the technical requirements of the building.',
      text12: 'a. Administrative requirements',
      text13: 'The administrative requirements for factory SLF management are as follows:',
      text15: 'Proof of the status of land rights or a utilization agreement if the owner of the building is not the holder of land rights.',
      text16: "Status kepemilikan bangunan gedung yang dilengkapi dengan KTP pemilik.The ownership status of the building is equipped with the owner's ID card.",
      text17: 'IMB (Building Permit) or PBG (Building Permit) document.',
      text18: 'Application letter for building function feasibility.',
      text19: 'Gambar as built drawing.',
      text20: 'Technical recommendations from regional apparatus related to fire protection systems, occupational safety and health (K3), electrical installations, and environmental impact control.',
      text21: 'Material test result documents.',
      text22: "The document of the results of testing and commissioning in the form of a list of architectural components, structures, utilities/installations, and the layout of the building's exterior space.",
      text23: 'Operation manual, maintenance and maintenance of building buildings, as well as functional equipment and equipment.',
      text24: 'b. Technical requirements',
      text25: 'The technical requirements for building buildings that will be requested by the local government when you take care of the issuance of a factory functional fitness certificate are as follows:',
      text26: 'Building planning requirements, which include building designation requirements, building intensity requirements, building architectural requirements, and environmental impact control requirements.',
      text27: 'Reliability requirements, which include safety requirements, health requirements, comfort requirements, and convenience requirements.',
      text28: 'Furthermore, the procedures for checking the feasibility of building functions that are usually carried out by SLF consultants are as follows:',
      text29: 'The process of checking the completeness of documents.',
      text30: 'Analysis and evaluation process.',
      text31: 'The process of preparing a report on the feasibility of building functions.',
      text32: 'Usually, the analysis and evaluation process required by the team of building experts from SLF consultants takes quite a long time, depending on the size and function of the building. In this analysis and evaluation process, there are at least 6 (six) stages. The explanation is as follows:',
      text33: ' SLF consultants or technical assessors will assess the suitability of the specifications and quality of construction implementation at each stage of work to the technical plan document of the building as an attachment to the IMB document (building permit).',
      text34: "Furthermore, examine the suitability of built drawings or as built drawings of buildings. If you do not have as built drawings, you can consult directly through the SLF consultants involved.",
      text35: 'Reviewing the fulfillment of technical recommendations from the relevant regional apparatus.',
      text36: 'Assess the conformity of material test results to technical specifications in the building technical plan document.',
      text37: 'Assess the suitability of the results of testing and testing of building equipment/equipment.',
      text38: "Finally, the SLF consultant will review the conformity of the technical specifications in the building's operation, maintenance, and maintenance manual.",
      text39: "The building functional feasibility inspection report prepared by a team of building experts will contain a list of the results of the building functional feasibility inspection. If the results of the inspection report state that the building is functional, the SLF consultant will provide you with a statement of the building's functional feasibility.",
      text40: 'However, if the results of the functional feasibility check state that the building is not functional, the SLF consultant will provide recommendations for repairs in accordance with the technical plan document of the building.',
      text41: 'However, if the results of the functional feasibility check state that the building is not functional, the SLF consultant will provide recommendations for repairs in accordance with the technical plan document of the building.',
      text42: ' Definition of factory according to laws and regulations',
      text43: 'A factory is a large industrial building used by workers to process goods or supervise the working process of machines from one product to another so that it has added value. In Indonesia itself, the term factory is also often referred to as an industrial building.',
      text44: ' Complementing the definition above, according to the Regulation of the Minister of Industry of the Republic of Indonesia Number 64/M-ID/PER/7/2016, the classification of industries in Indonesia can be seen based on the number of workers and the value of investment.',
      text45: '  1. Small industry, is an industry that employs a maximum of 19 (nineteen) workers and has an investment value of less than 1 (one) billion rupiah (excluding land and buildings where the business is located). ',
      text46: '  2. Medium industry, is an industry that employs a maximum of 19 (nineteen) workers and has an investment value of at least 1 (one) billion rupiah or employs at least 20 (twenty) workers and has an investment value of at most 15 (fifteen) billion rupiah.',
      text47: '   3. Large industries are industries that employ at least 20 (twenty) workers and have an investment value of more than 15 (fifteen) billion rupiah.',
      text48: 'One of the importance of SLF for buildings is to ensure that every work machine can operate normally and is maintained regularly',
      text49: ' Why is SLF for factories important?',
      text50: '  Why is SLF important? The older the factory and its machines, the more often problems will arise. For example, the fragility of the walls due to the aging of the factory, the non-functioning of the system and fire alarm when there is a blaze in the workspace, or other problems that often arise without being predictable.',
      text51: ' To suppress and minimize work accidents that can take lives, the government organizes and fosters the implementation of building buildings through the management of factory SLFs. The regulation contained in the legislation explains that the benchmark for the reliability of buildings used as factories can be seen from the existence of SLF or certificate of functional fitness.',
      text52: 'The validity period of the certificate of functional fitness for the factory has also been determined, namely for 5 (five years). That way, before the validity period of the factory SLF expires, you must apply for an extension of the certificate of functional fitness by completing several documents that have been required.',
      text53: ' On the other hand, there are several benefits when a factory that is still actively operating has a certificate of functional fitness. The benefits of SLF for factories are as follows:',
      text54: 'Realizing a factory building that is functionally fit and has a building layout that is harmonious and in harmony with the environment.',
      text55: 'Realizing order in the implementation of factory buildings to ensure reliability, both in terms of safety, health, comfort, and convenience.',
      text56: 'Realizing legal certainty in the implementation of building buildings.',
      text57: "So, that's an explanation of the importance of SLF or certificate of functional fitness for factory buildings that are still actively operating. If you are having trouble finding an SLF service provider in your city, using our services is the right choice.",
      // Tambahkan kunci lainnya di sini
    }
  },
  id: {
    message: {
      title: "Pentingnya SLF untuk Gedung Pabrik",
      text1: "Saat ini, pemerintah Indonesia mengharuskan gedung, terutama pabrik, untuk mengurus SLF atau Sertifikat Laik Fungsi. SLF adalah sertifikat yang dikeluarkan oleh pemerintah daerah untuk gedung yang telah selesai dibangun sesuai dengan IMB dan telah memenuhi persyaratan kelayakan teknis sesuai dengan fungsi gedung berdasarkan hasil pemeriksaan dari tim ahli bangunan atau jasa konsultan SLF.",
      text2: "Tujuan utama penerbitan sertifikat laik fungsi adalah sebagai syarat untuk penggunaan gedung. Selain itu, adanya sertifikat laik fungsi dapat diartikan bahwa gedung yang Anda gunakan telah diuji keandalannya.",
      text3: "Namun, sebelum kita membahas kewajiban mengelola sertifikat laik fungsi untuk gedung pabrik, ada poin-poin penting yang harus Anda perhatikan. Untuk itu, simak artikel ini hingga akhir!",
      text4: "Regulasi tentang kewajiban pengelolaan SLF",
      text5: "Seperti yang telah dijelaskan sebelumnya, pengelolaan sertifikat laik fungsi telah diatur oleh pemerintah Indonesia melalui undang-undang dan peraturan yang jelas. Undang-undang dan peraturan mengenai kewajiban pengelolaan sertifikat laik fungsi adalah sebagai berikut:",
      text6: "Undang-Undang Republik Indonesia Nomor 28 Tahun 2002 tentang Bangunan Gedung.",
      text7: "Peraturan Pemerintah (PP) Nomor 36 Tahun 2005 tentang Peraturan Pelaksanaan Undang-Undang Nomor 28 Tahun 2002 tentang Bangunan Gedung.",
      text8: "Peraturan Menteri Pekerjaan Umum dan Perumahan Rakyat Nomor 27/PRT/M/2018 tentang Sertifikat Laik Fungsi Bangunan Gedung.",
      text9: "Peraturan Menteri Pekerjaan Umum dan Perumahan Rakyat Republik Indonesia Nomor 3 Tahun 2020 tentang Perubahan atas Peraturan Menteri Pekerjaan Umum dan Perumahan Rakyat Nomor 27/PRT/M/2018 tentang Sertifikat Laik Fungsi Bangunan Gedung.",
      text10: "Apa yang Dinilai dalam Pengelolaan SLF Pabrik?",
      text11: "Agar pemerintah daerah dapat menerbitkan SLF untuk pabrik Anda, ada dua syarat utama yang harus dipenuhi. Pertama, adalah persyaratan administratif. Kedua, adalah persyaratan teknis bangunan.",
      text12: "a. Persyaratan Administratif",
      text13: "Persyaratan administratif untuk pengelolaan SLF pabrik adalah sebagai berikut:",
      text15: "Bukti status hak atas tanah atau perjanjian pemanfaatan jika pemilik gedung bukan pemegang hak tanah.",
      text16: "Status kepemilikan gedung yang dilengkapi dengan KTP pemilik.",
      text17: "Dokumen IMB (Izin Mendirikan Bangunan) atau PBG (Perizinan Bangunan Gedung).",
      text18: "Surat permohonan kelayakan fungsi bangunan.",
      text19: "Gambar as built drawing.",
      text20: "Rekomendasi teknis dari aparat daerah terkait sistem perlindungan kebakaran, keselamatan dan kesehatan kerja (K3), instalasi listrik, dan pengendalian dampak lingkungan.",
      text21: "Dokumen hasil uji material.",
      text22: "Dokumen hasil pengujian dan commissioning berupa daftar komponen arsitektur, struktur, utilitas/instalasi, dan tata letak ruang luar bangunan.",
      text23: "Manual operasional, pemeliharaan dan perawatan bangunan, serta peralatan dan perlengkapan fungsional.",
      text24: "b. Persyaratan Teknis",
      text25: "Persyaratan teknis untuk gedung yang akan diminta oleh pemerintah daerah saat Anda mengurus penerbitan sertifikat laik fungsi pabrik adalah sebagai berikut:",
      text26: "Persyaratan perencanaan bangunan, yang meliputi persyaratan penetapan fungsi bangunan, persyaratan intensitas bangunan, persyaratan arsitektur bangunan, dan persyaratan pengendalian dampak lingkungan.",
      text27: "Persyaratan keandalan, yang meliputi persyaratan keselamatan, kesehatan, kenyamanan, dan kemudahan.",
      text28: "Selanjutnya, prosedur pemeriksaan kelayakan fungsi bangunan yang biasanya dilakukan oleh konsultan SLF adalah sebagai berikut:",
      text29: "Proses pemeriksaan kelengkapan dokumen.",
      text30: "Proses analisis dan evaluasi.",
      text31: "Proses penyusunan laporan kelayakan fungsi bangunan.",
      text32: "Biasanya, proses analisis dan evaluasi yang diperlukan oleh tim ahli bangunan dari konsultan SLF memerlukan waktu cukup lama, tergantung pada ukuran dan fungsi gedung. Dalam proses analisis dan evaluasi ini, terdapat minimal 6 (enam) tahapan. Penjelasannya adalah sebagai berikut:",

      text33: "Konsultan SLF atau penilai teknis akan menilai kesesuaian spesifikasi dan kualitas pelaksanaan konstruksi pada setiap tahapan pekerjaan terhadap dokumen rencana teknis bangunan sebagai lampiran dokumen IMB (izin mendirikan bangunan)."
      ,
      text34: "Selanjutnya, memeriksa kesesuaian gambar as built atau gambar as built bangunan. Jika Anda tidak memiliki gambar as built, Anda dapat berkonsultasi langsung melalui konsultan SLF yang terlibat."
      ,
      text35: "Menelaah pemenuhan rekomendasi teknis dari aparat daerah terkait."
      ,
      text36: "Menilai kesesuaian hasil uji material terhadap spesifikasi teknis dalam dokumen rencana teknis bangunan."
      ,
      text37: "Menilai kesesuaian hasil pengujian dan pengujian peralatan/perlengkapan bangunan."
      ,
      text38: "Terakhir, konsultan SLF akan menelaah kesesuaian spesifikasi teknis dalam manual operasional, pemeliharaan, dan perawatan bangunan."
      ,
      text39: "Laporan pemeriksaan kelayakan fungsi bangunan yang disusun oleh tim ahli bangunan akan memuat daftar hasil pemeriksaan kelayakan fungsi bangunan. Jika hasil laporan pemeriksaan menyatakan bahwa bangunan layak fungsi, konsultan SLF akan memberikan pernyataan kelayakan fungsi bangunan."
      ,
      text40: "Namun, jika hasil pemeriksaan kelayakan fungsi menyatakan bahwa bangunan tidak layak fungsi, konsultan SLF akan memberikan rekomendasi perbaikan sesuai dengan dokumen rencana teknis bangunan."
      ,
      text41: "Namun, jika hasil pemeriksaan kelayakan fungsi menyatakan bahwa bangunan tidak layak fungsi, konsultan SLF akan memberikan rekomendasi perbaikan sesuai dengan dokumen rencana teknis bangunan."
      ,
      text42: "Definisi Pabrik menurut Undang-Undang dan Peraturan"
      ,
      text43: "Pabrik adalah gedung industri besar yang digunakan oleh pekerja untuk mengolah barang atau mengawasi proses kerja mesin dari satu produk ke produk lainnya sehingga memiliki nilai tambah. Di Indonesia, istilah pabrik juga sering disebut sebagai gedung industri."
      ,
      text44: "Melengkapi definisi di atas, menurut Peraturan Menteri Perindustrian Republik Indonesia Nomor 64/M-ID/PER/7/2016, klasifikasi industri di Indonesia dapat dilihat berdasarkan jumlah pekerja dan nilai investasi."
      ,
      text45: "1. Industri kecil, adalah industri yang mempekerjakan maksimal 19 (sembilan belas) pekerja dan memiliki nilai investasi kurang dari 1 (satu) miliar rupiah (tidak termasuk tanah dan bangunan tempat usaha)."
      ,
      text46: "2. Industri menengah, adalah industri yang mempekerjakan maksimal 19 (sembilan belas) pekerja dan memiliki nilai investasi minimal 1 (satu) miliar rupiah atau mempekerjakan setidaknya 20 (dua puluh) pekerja dan memiliki nilai investasi maksimal 15 (lima belas) miliar rupiah."
      ,
      text47: "3. Industri besar adalah industri yang mempekerjakan setidaknya 20 (dua puluh) pekerja dan memiliki nilai investasi lebih dari 15 (lima belas) miliar rupiah."
      ,
      text48: "Salah satu pentingnya SLF untuk gedung adalah untuk memastikan bahwa setiap mesin kerja dapat beroperasi dengan normal dan dirawat secara berkala."
      ,
      text49: "Mengapa SLF untuk pabrik itu penting?"
      ,
      text50: "Mengapa SLF penting? Semakin tua pabrik dan mesinnya, semakin sering masalah akan muncul. Misalnya, kerapuhan dinding akibat usia pabrik, sistem dan alarm kebakaran yang tidak berfungsi saat terjadi kebakaran di area kerja, atau masalah lainnya yang sering muncul tanpa dapat diprediksi."
      ,
      text51: "Untuk menekan dan meminimalkan kecelakaan kerja yang dapat merenggut nyawa, pemerintah menyelenggarakan dan membina pelaksanaan gedung pabrik melalui pengelolaan SLF pabrik. Regulasi yang terdapat dalam undang-undang menjelaskan bahwa tolok ukur keandalan gedung yang digunakan sebagai pabrik dapat dilihat dari adanya SLF atau sertifikat laik fungsi."
      ,
      text52: "Masa berlaku sertifikat laik fungsi pabrik juga telah ditentukan, yaitu selama 5 (lima) tahun. Dengan begitu, sebelum masa berlaku SLF pabrik Anda habis, Anda harus mengajukan perpanjangan sertifikat laik fungsi dengan melengkapi beberapa dokumen yang telah ditentukan."
      ,
      text53: "Di sisi lain, ada beberapa manfaat ketika pabrik yang masih aktif beroperasi memiliki sertifikat laik fungsi. Manfaat SLF untuk pabrik adalah sebagai berikut:"
      ,
      text54: "Mewujudkan gedung pabrik yang laik fungsi dan memiliki tata letak bangunan yang harmonis dan sesuai dengan lingkungan."
      ,
      text55: "Mewujudkan keteraturan dalam pelaksanaan gedung pabrik untuk memastikan keandalan, baik dari segi keselamatan, kesehatan, kenyamanan, dan kemudahan."
      ,
      text56: "Mewujudkan kepastian hukum dalam pelaksanaan gedung pabrik."
      ,
      text57: "Jadi, itulah penjelasan tentang pentingnya SLF atau sertifikat laik fungsi untuk gedung pabrik yang masih aktif beroperasi. Jika Anda kesulitan mencari penyedia layanan SLF di kota Anda, menggunakan layanan kami adalah pilihan yang tepat."
    }
  }
};

const i18n = new VueI18n({
  locale: 'id', // Locale default
  messages,
});

export default i18n;
