<template>
    <div>
        <div class="destop">
            <div class="mainnArtikel">
                <div class="titleMain">
                    <p class="artikeltitle1">Artikel dan Berita</p>
                    <p>Artikel ditulis berdasarkan pengalaman dan portofolio selama di lapangan</p>
                </div>
                <div class="viewTampilan">
                    <div class="blog  rounded-lg shadow">
                        <!-- <router-link to="artikelOne"> -->
                        <a href="#" data-modal-target="default-modal" data-modal-toggle="default-modal">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight  dark:text-white text-white">Pentingnya
                                SLF untuk Bangunan Pabrik</h5>
                        </a>
                        <!-- </router-link> -->
                        <p class="mb-3 font-normal text-white dark:text-gray-400">MAR 15</p>
                        <!-- <router-link to="artikelOne"
                            class="inline-flex items-center px-3 py-2 mt-56 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            style="bottom: 0px;">
                            Read more
                            <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                            </svg>
                        </router-link> -->


                        <!-- Modal toggle -->
                        <button data-modal-target="default-modal" data-modal-toggle="default-modal"
                            class="inline-flex items-center px-3 py-2 mt-56 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            style="bottom: 0px;" type="button">
                            Read more
                            <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                            </svg>
                        </button>

                        <!-- Main modal -->
                        <div id="default-modal" tabindex="-1" aria-hidden="true"
                            class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                            <div class="relative p-4 w-full max-w-2xl max-h-full">
                                <!-- Modal content -->
                                <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                    <!-- Modal header -->
                                    <div
                                        class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                        <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                                            {{ $t('message.title') }}
                                        </h3>
                                        <button type="button"
                                            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                            data-modal-hide="default-modal">
                                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                fill="none" viewBox="0 0 14 14">
                                                <path stroke="currentColor" stroke-linecap="round"
                                                    stroke-linejoin="round" stroke-width="2"
                                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                            </svg>
                                            <span class="sr-only">Close modal</span>
                                        </button>
                                    </div>
                                    <!-- Modal body -->
                                    <div class="p-4 md:p-5 space-y-4">
                                        <div>
                                            <div class="translate">
                                                <LanguageSwitcher />
                                            </div>
                                            <div class="ArtikelDestop">
                                                <div class="containerrr">
                                                    <div>

                                                        <div class="titleartikell">
                                                            <p>{{ $t('message.title') }}</p>
                                                        </div>
                                                        <div class="imgdivv">
                                                            <img class="imgartikel1"
                                                                :src="require('@/assets/img/img1.jpg')" alt="">
                                                        </div>
                                                        <div class="artikel">
                                                            <p style="margin-bottom: 20px; margin-top: 20px">
                                                                {{ $t('message.text1') }}
                                                            </p>
                                                            <p style="margin-bottom: 20px; ">
                                                                {{ $t('message.text2') }}
                                                            </p>
                                                            <p style="margin-bottom: 20px; ">
                                                                {{ $t('message.text3') }}
                                                            </p>
                                                            <p class="titleartikel2">
                                                                {{ $t('message.text4') }}
                                                            </p>
                                                            <p style="margin-bottom: 20px; ">
                                                                {{ $t('message.text5') }}
                                                            </p>
                                                            <p class=" space-y-1 list-decimal list-inside">
                                                            <ul
                                                                class="space-y-4 text-gray-900 list-disc list-inside dark:text-gray-900">
                                                                <ol
                                                                    class="ps-5 mt-2 space-y-1 list-decimal list-inside">
                                                                    <li>{{ $t('message.text6') }} </li>
                                                                    <li>{{ $t('message.text7') }}</li>
                                                                    <li>{{ $t('message.text8') }}</li>
                                                                    <li>{{ $t('message.text9') }}</li>
                                                                </ol>
                                                            </ul>
                                                            </p>
                                                            <p class="titleartikel3">
                                                                {{ $t('message.text10') }}
                                                            </p>
                                                            <p style="text-align: left; margin-bottom: 20px;">{{
                                                                $t('message.text11') }}</p>
                                                            <p class=""
                                                                style="font-size: 22px;font-weight: bold;color: #3687aa;margin-top: 20px;">
                                                                {{ $t('message.text12') }}
                                                            </p>
                                                            <p>
                                                                {{ $t('message.text13') }}
                                                            </p>
                                                            <p>
                                                            <ul
                                                                class="space-y-4 text-gray-900 list-disc list-inside dark:text-gray-900">
                                                                <ol
                                                                    class="ps-5 mt-2 space-y-1 list-decimal list-inside">
                                                                    <li> {{ $t('message.text15') }}</li>
                                                                    <li> {{ $t('message.text16') }}</li>
                                                                    <li> {{ $t('message.text17') }}</li>
                                                                    <li> {{ $t('message.text18') }}</li>
                                                                    <li> {{ $t('message.text19') }}</li>
                                                                    <li> {{ $t('message.text20') }}</li>
                                                                    <li> {{ $t('message.text21') }}</li>
                                                                    <li> {{ $t('message.text22') }}</li>
                                                                    <li>{{ $t('message.text23') }}</li>
                                                                </ol>
                                                            </ul>
                                                            </p>
                                                            <p class="titleartikel4">
                                                                {{ $t('message.text25') }}
                                                            </p>
                                                            <p>
                                                                {{ $t('message.text26') }}
                                                            </p>
                                                            <p>
                                                            <ul
                                                                class="space-y-4 text-gray-900 list-disc list-inside dark:text-gray-900">
                                                                <ol
                                                                    class="ps-5 mt-2 space-y-1 list-decimal list-inside">
                                                                    <li>{{ $t('message.text27') }}</li>
                                                                    <li>{{ $t('message.text28') }}</li>
                                                                </ol>
                                                            </ul>
                                                            </p>
                                                            <p>
                                                                {{ $t('message.text29') }}
                                                            </p>
                                                            <p>
                                                            <ul
                                                                class="space-y-4 text-gray-900 list-disc list-inside dark:text-gray-900">
                                                                <ol
                                                                    class="ps-5 mt-2 space-y-1 list-decimal list-inside">
                                                                    <li>{{ $t('message.text30') }}</li>
                                                                    <li>{{ $t('message.text31') }}</li>
                                                                    <li>{{ $t('message.text32') }}</li>
                                                                </ol>
                                                            </ul>
                                                            </p>
                                                            <p>
                                                                {{ $t('message.text33') }}
                                                            </p>
                                                            <p>
                                                            <ul
                                                                class="space-y-4 text-gray-900 list-disc list-inside dark:text-gray-900">
                                                                <ol
                                                                    class="ps-5 mt-2 space-y-1 list-decimal list-inside">
                                                                    <li>{{ $t('message.text34') }}</li>
                                                                    <li>{{ $t('message.text35') }}</li>
                                                                    <li>{{ $t('message.text36') }}</li>
                                                                    <li>{{ $t('message.text37') }}</li>
                                                                    <li>{{ $t('message.text38') }}</li>
                                                                    <li>{{ $t('message.text39') }}</li>
                                                                </ol>
                                                            </ul>
                                                            </p>
                                                            <p style="text-align: left; margin-bottom: 20px;">
                                                                {{ $t('message.text40') }}
                                                            </p>
                                                            <p>
                                                                {{ $t('message.text41') }}
                                                            </p>
                                                            <p class="titleartikel5">
                                                                {{ $t('message.text42') }}
                                                            </p>
                                                            <p style="text-align: left; margin-bottom: 20px;">
                                                                {{ $t('message.text43') }}
                                                            </p>
                                                            <p style="text-align: left; margin-bottom: 20px;">
                                                                {{ $t('message.text44') }}
                                                            </p>
                                                            <p style="text-align: left; margin-bottom: 20px;">
                                                                {{ $t('message.text45') }}
                                                                <br>
                                                                {{ $t('message.text46') }}
                                                                <br>
                                                                {{ $t('message.text47') }}
                                                                <br>
                                                            </p>
                                                            <img style="text-align: left; margin-bottom: 20px; width: 100%; height: 500px; object-fit: cover;border-radius: 10px;"
                                                                :src="require('@/assets/img/SLF-pabrik.jpg')" alt="">
                                                            <p>
                                                                {{ $t('message.text48') }}
                                                            </p>
                                                            <p class="titleartikel6">
                                                                {{ $t('message.text49') }}
                                                            </p>
                                                            <p style="text-align: left; margin-bottom: 20px;">
                                                                {{ $t('message.text50') }}
                                                            </p>
                                                            <p style="text-align: left; margin-bottom: 20px;">
                                                                {{ $t('message.text51') }}
                                                            </p>
                                                            <p style="text-align: left; margin-bottom: 20px;">
                                                                {{ $t('message.text52') }}
                                                            </p>
                                                            <p style="text-align: left; margin-bottom: 20px;">
                                                                {{ $t('message.text53') }}
                                                            </p>
                                                            <p style="text-align: left; margin-bottom: 20px;">
                                                            <ul
                                                                class="space-y-4 text-gray-900 list-disc list-inside dark:text-gray-900">
                                                                <ol
                                                                    class="ps-5 mt-2 space-y-1 list-decimal list-inside">
                                                                    <li>{{ $t('message.text54') }} </li>
                                                                    <li>{{ $t('message.text55') }}</li>
                                                                    <li>{{ $t('message.text56') }}</li>
                                                                </ol>
                                                            </ul>
                                                            </p>
                                                            <p style="text-align: left; margin-bottom: 20px;">
                                                                {{ $t('message.text57') }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Modal footer -->
                                    <!-- <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button data-modal-hide="default-modal" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">I accept</button>
                <button data-modal-hide="default-modal" type="button" class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Decline</button>
            </div> -->
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <div class="AppMobilee">
            <div class="mainnn">
                <div class="titleMain">
                    <p class="artikeltitle1">Artikel dan Berita</p>
                    <p style="font-size: 12px; " class="px-5">Artikel ditulis berdasarkan pengalaman dan portofolio
                        selama di lapangan</p>
                </div>
                <div class="viewTampilan">
                    <div class="blog  rounded-lg shadow">
                        <a href="#" data-modal-target="default-modall" data-modal-toggle="default-modall">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight  dark:text-white text-white">Pentingnya
                                SLF untuk Bangunan Pabrik</h5>
                        </a>
                        <p class="mb-3 font-normal text-white dark:text-gray-400">MAR 15</p>
                        <!-- <router-link to="artikelOne"
                            class="inline-flex items-center px-3 py-2 mt-32 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            style="bottom: 0px;">
                            Read more
                            <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                            </svg>
                        </router-link> -->
                        <!-- Modal toggle -->
                        <button data-modal-target="default-modall" data-modal-toggle="default-modall"
                            class="inline-flex items-center px-3 py-2 mt-28 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            style="bottom: 0px;" type="button">
                            Read more
                            <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                            </svg>
                        </button>

                        <!-- Main modal -->
                        <div id="default-modall" tabindex="-1" aria-hidden="true"
                            class="hidden overflow-y-auto overflow-x-hidden fixed top-14 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                            <div class="relative p-4 w-full max-w-2xl max-h-full">
                                <!-- Modal content -->
                                <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                    <!-- Modal header -->
                                    <div
                                        class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                        <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                                            {{ $t('message.title') }}
                                        </h3>
                                        
                                    </div>
                                    <!-- Modal body -->
                                    <div class="p-4 md:p-5 space-y-4">
                                        <div>
                                            <div class="translatee">
                                                <LanguageSwitcher />
                                                <button type="button"
                                            class="text-gray-400 bg-transparent mt-2 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                            data-modal-hide="default-modall">
                                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                fill="none" viewBox="0 0 14 14">
                                                <path stroke="currentColor" stroke-linecap="round"
                                                    stroke-linejoin="round" stroke-width="2"
                                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                            </svg>
                                            <span class="sr-only">Close modal</span>
                                        </button>
                                            </div>
                                            <div class="ArtikelDestop">
                                                <div class="containerrr">
                                                    <div>

                                                        <div class="titleartikell">
                                                            <p>{{ $t('message.title') }}</p>
                                                        </div>
                                                        <div class="imgdivv">
                                                            <img class="imgartikel1"
                                                                :src="require('@/assets/img/img1.jpg')" alt="">
                                                        </div>
                                                        <div class="artikel">
                                                            <p style="margin-bottom: 20px; margin-top: 20px">
                                                                {{ $t('message.text1') }}
                                                            </p>
                                                            <p style="margin-bottom: 20px; ">
                                                                {{ $t('message.text2') }}
                                                            </p>
                                                            <p style="margin-bottom: 20px; ">
                                                                {{ $t('message.text3') }}
                                                            </p>
                                                            <p class="titleartikel2">
                                                                {{ $t('message.text4') }}
                                                            </p>
                                                            <p style="margin-bottom: 20px; ">
                                                                {{ $t('message.text5') }}
                                                            </p>
                                                            <p class=" space-y-1 list-decimal list-inside">
                                                            <ul
                                                                class="space-y-4 text-gray-900 list-disc list-inside dark:text-gray-900">
                                                                <ol
                                                                    class="ps-5 mt-2 space-y-1 list-decimal list-inside">
                                                                    <li>{{ $t('message.text6') }} </li>
                                                                    <li>{{ $t('message.text7') }}</li>
                                                                    <li>{{ $t('message.text8') }}</li>
                                                                    <li>{{ $t('message.text9') }}</li>
                                                                </ol>
                                                            </ul>
                                                            </p>
                                                            <p class="titleartikel3">
                                                                {{ $t('message.text10') }}
                                                            </p>
                                                            <p style="text-align: left; margin-bottom: 20px;">{{
                                                                $t('message.text11') }}</p>
                                                            <p class=""
                                                                style="font-size: 22px;font-weight: bold;color: #3687aa;margin-top: 20px;">
                                                                {{ $t('message.text12') }}
                                                            </p>
                                                            <p>
                                                                {{ $t('message.text13') }}
                                                            </p>
                                                            <p>
                                                            <ul
                                                                class="space-y-4 text-gray-900 list-disc list-inside dark:text-gray-900">
                                                                <ol
                                                                    class="ps-5 mt-2 space-y-1 list-decimal list-inside">
                                                                    <li> {{ $t('message.text15') }}</li>
                                                                    <li> {{ $t('message.text16') }}</li>
                                                                    <li> {{ $t('message.text17') }}</li>
                                                                    <li> {{ $t('message.text18') }}</li>
                                                                    <li> {{ $t('message.text19') }}</li>
                                                                    <li> {{ $t('message.text20') }}</li>
                                                                    <li> {{ $t('message.text21') }}</li>
                                                                    <li> {{ $t('message.text22') }}</li>
                                                                    <li>{{ $t('message.text23') }}</li>
                                                                </ol>
                                                            </ul>
                                                            </p>
                                                            <p class="titleartikel4">
                                                                {{ $t('message.text25') }}
                                                            </p>
                                                            <p>
                                                                {{ $t('message.text26') }}
                                                            </p>
                                                            <p>
                                                            <ul
                                                                class="space-y-4 text-gray-900 list-disc list-inside dark:text-gray-900">
                                                                <ol
                                                                    class="ps-5 mt-2 space-y-1 list-decimal list-inside">
                                                                    <li>{{ $t('message.text27') }}</li>
                                                                    <li>{{ $t('message.text28') }}</li>
                                                                </ol>
                                                            </ul>
                                                            </p>
                                                            <p>
                                                                {{ $t('message.text29') }}
                                                            </p>
                                                            <p>
                                                            <ul
                                                                class="space-y-4 text-gray-900 list-disc list-inside dark:text-gray-900">
                                                                <ol
                                                                    class="ps-5 mt-2 space-y-1 list-decimal list-inside">
                                                                    <li>{{ $t('message.text30') }}</li>
                                                                    <li>{{ $t('message.text31') }}</li>
                                                                    <li>{{ $t('message.text32') }}</li>
                                                                </ol>
                                                            </ul>
                                                            </p>
                                                            <p>
                                                                {{ $t('message.text33') }}
                                                            </p>
                                                            <p>
                                                            <ul
                                                                class="space-y-4 text-gray-900 list-disc list-inside dark:text-gray-900">
                                                                <ol
                                                                    class="ps-5 mt-2 space-y-1 list-decimal list-inside">
                                                                    <li>{{ $t('message.text34') }}</li>
                                                                    <li>{{ $t('message.text35') }}</li>
                                                                    <li>{{ $t('message.text36') }}</li>
                                                                    <li>{{ $t('message.text37') }}</li>
                                                                    <li>{{ $t('message.text38') }}</li>
                                                                    <li>{{ $t('message.text39') }}</li>
                                                                </ol>
                                                            </ul>
                                                            </p>
                                                            <p style="text-align: left; margin-bottom: 20px;">
                                                                {{ $t('message.text40') }}
                                                            </p>
                                                            <p>
                                                                {{ $t('message.text41') }}
                                                            </p>
                                                            <p class="titleartikel5">
                                                                {{ $t('message.text42') }}
                                                            </p>
                                                            <p style="text-align: left; margin-bottom: 20px;">
                                                                {{ $t('message.text43') }}
                                                            </p>
                                                            <p style="text-align: left; margin-bottom: 20px;">
                                                                {{ $t('message.text44') }}
                                                            </p>
                                                            <p style="text-align: left; margin-bottom: 20px;">
                                                                {{ $t('message.text45') }}
                                                                <br>
                                                                {{ $t('message.text46') }}
                                                                <br>
                                                                {{ $t('message.text47') }}
                                                                <br>
                                                            </p>
                                                            <img style="text-align: left; margin-bottom: 20px; width: 100%; height: 500px; object-fit: cover;border-radius: 10px;"
                                                                :src="require('@/assets/img/SLF-pabrik.jpg')" alt="">
                                                            <p>
                                                                {{ $t('message.text48') }}
                                                            </p>
                                                            <p class="titleartikel6">
                                                                {{ $t('message.text49') }}
                                                            </p>
                                                            <p style="text-align: left; margin-bottom: 20px;">
                                                                {{ $t('message.text50') }}
                                                            </p>
                                                            <p style="text-align: left; margin-bottom: 20px;">
                                                                {{ $t('message.text51') }}
                                                            </p>
                                                            <p style="text-align: left; margin-bottom: 20px;">
                                                                {{ $t('message.text52') }}
                                                            </p>
                                                            <p style="text-align: left; margin-bottom: 20px;">
                                                                {{ $t('message.text53') }}
                                                            </p>
                                                            <p style="text-align: left; margin-bottom: 20px;">
                                                            <ul
                                                                class="space-y-4 text-gray-900 list-disc list-inside dark:text-gray-900">
                                                                <ol
                                                                    class="ps-5 mt-2 space-y-1 list-decimal list-inside">
                                                                    <li>{{ $t('message.text54') }} </li>
                                                                    <li>{{ $t('message.text55') }}</li>
                                                                    <li>{{ $t('message.text56') }}</li>
                                                                </ol>
                                                            </ul>
                                                            </p>
                                                            <p style="text-align: left; margin-bottom: 20px;">
                                                                {{ $t('message.text57') }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Modal footer -->
                                    <!-- <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button data-modal-hide="default-modall" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">I accept</button>
                <button data-modal-hide="default-modall" type="button" class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Decline</button>
            </div> -->
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import CustomFooter from '@/components/CustomFooter.vue';
import LanguageSwitcher from '../components/LanguageSwitcher.vue';
export default {
    name: 'AxrtikelOne',
    components: {
        // CustomFooter,
        LanguageSwitcher
    },
    views: {
    }
}
</script>

<style>
@media (min-width: 1280px) {
    .AppMobilee {
        display: none;
    }

    .main {
        padding-top: 50px;
    }

    .artikeltitle1 {
        font-weight: bold;
        font-size: 38px;
    }

    .blog {
        background: url('../assets/img/img1.jpg');
        width: 300px;
        height: 400px;
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        color: #fff;
        text-align: left;
        padding: 10px;
        margin: 20px;
    }
    .containerrr {
    margin-left: 1%;
    margin-right: 1%;
    display: flex;
    justify-content: center;
    /* background-color: aqua; */
}

.imgdivv {
    width: 100%;
    height: 400px;
    /* Tinggi tetap 500px */
    overflow: hidden;
    /* Untuk memastikan gambar tidak keluar dari div */
    border-radius: 10px;
}

.imgartikel1 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Menjaga rasio aspek gambar dan memastikan gambar tidak pecah */
    border-radius: 10px;

}


.artikel p {
    text-align: left;
}

.titleartikell p {
    font-weight: bold;
    font-size: 40px;
    padding: 20px;
}

.angkerBackHom i {
    font-size: 100px;
}

.angkerBackHome {
    position: absolute;
    left: 10px;
    top: 6px;
    background-color: rgba(white, white, white, 0.8);
    border-radius: 50%;
    padding: 5px;
}

.angkerBackHome::hover {
    background-color: rgba(white, white, white, 0.8);
}

.titleartikel2,
.titleartikel3,
.titleartikel4,
.titleartikel5,
.titleartikel6 {
    font-size: 22px;
    font-weight: bold;
    color: #3687aa;
    margin-top: 20px;
    margin-bottom: 20px;
}

.translate {
    position: sticky;
    top: 0;
    /* Posisi dari atas viewport */
    display: flex;
    gap: 10px;
    margin: 10px 0;
    background-color: white;
    /* Untuk memastikan tombol tidak kehilangan latar belakang saat digulir */
    z-index: 1000;
    /* Pastikan tombol tetap di atas konten lainnya */
    padding-left: 50px;
    border-bottom: 1px solid red;
}
}

@media (max-width: 600px) {
    .destop {
        display: none;
    }

    .mainn {
        padding-top: 50px;
    }

    /* .AppMobilee{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
} */

    .artikeltitle1 {
        font-weight: bold;
        font-size: 20px;
    }

    .blog {
        background: url('../assets/img/img1.jpg');
        width: 280px;
        height: 300px;
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        color: #fff;
        text-align: left;
        padding: 10px;
        margin: 20px;
    }

    .mainnArtikel {
        margin-top: 20px;
    }
    .translatee {
    position: sticky;
    top: 0;
    display: flex;
    gap: 10px;
    background-color: white;
    z-index: 1000;
    border-bottom: 1px solid red;
}
}


</style>